import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`26th July 2024`}</p>


    <h2 {...{
      "id": "️-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-bug-fixes",
        "aria-label": "️ bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🛠️ Bug fixes`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`NullPointerException`}</inlineCode>{` is no longer raised when `}<a parentName="li" {...{
          "href": "type://GraphqlService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/graphql/GraphqlService.html"
        }}>{`type://GraphqlService`}</a>{` handles errors. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5815"
        }}>{`#5815`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5816"
        }}>{`#5816`}</a></li>
      <li parentName="ul">{`Fixed a regression where a protocol violation error is not handled
by `}<a parentName="li" {...{
          "href": "type://ServerErrorHandler#onProtocolViolation(ServiceConfig,RequestHeaders,HttpStatus,String,Throwable):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerErrorHandler.html#onProtocolViolation(com.linecorp.armeria.server.ServiceConfig,com.linecorp.armeria.common.RequestHeaders,com.linecorp.armeria.common.HttpStatus,java.lang.String,java.lang.Throwable)"
        }}>{`type://ServerErrorHandler#onProtocolViolation(ServiceConfig,RequestHeaders,HttpStatus,String,Throwable)`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5811"
        }}>{`#5811`}</a></li>
      <li parentName="ul">{`Fixed compatibility issue with Java 9 modules by excluding `}<inlineCode parentName="li">{`java.security.Provider`}</inlineCode>{` SPI configuration from
the shadowed JAR. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5825"
        }}>{`#5825`}</a></li>
      <li parentName="ul">{`Reapplied changes that were unexpectedly overwritten for Java module support. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5835"
        }}>{`#5835`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://GrpcService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcService.html"
        }}>{`type://GrpcService`}</a>{` now correctly returns `}<inlineCode parentName="li">{`RESOURCE_EXHAUSTED`}</inlineCode>{` status when a response message exceeds the
maximum allowed length. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5818"
        }}>{`#5818`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5824"
        }}>{`#5824`}</a></li>
      <li parentName="ul">{`Relaxed constraints on gRPC JSON transcoding to log a warning instead of raising an `}<inlineCode parentName="li">{`IllegalArgumentException`}</inlineCode>{`
when used with non-unary gRPC methods. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5828"
        }}>{`#5828`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5829"
        }}>{`#5829`}</a></li>
    </ul>
    <h2 {...{
      "id": "-thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-thank-you",
        "aria-label": " thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙇 Thank you`}</h2>
    <ThankYou usernames={['Dogacel', 'ikhoon', 'jrhee17', 'linw50', 'minwoox', 'trustin', 'yzfeng2020']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      